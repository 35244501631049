<template>
  <div class="unauthenticated-actions-background">
    <div class="unauthenticated-actions-container scrollbar">
      <CommonsNotifier />
      <NuxtPwaManifest />

      <img class="partner-logo" :src="`${hostS3}${logoS3}`" alt="Logo" >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const { isApple } = useDevice();

useHead({
  bodyAttrs: {
    class: isApple ? "" : "designed-scrollbar",
  },
});

const brandComposable = useBrandsComposable();

const hostS3: string | undefined = brandComposable.getClientBrandConfig().urlS3;
const logoS3: string | undefined = brandComposable.getClientBrandConfig().logoS3;
</script>

<style lang="scss" scoped>
.partner-logo {
  max-width: 360px;
  max-height: 50px;
  margin-bottom: $uds-spacing-2;
  padding: 10px;

  img {
    height: 55px;
    width: 55px;
  }
}
.unauthenticated-actions-background {
  background-image: url("~/assets/svg/bglandingpage.svg");
  background-size: cover;
}
.unauthenticated-actions-container {
  height: 100vh;
  width: 100%;
}
</style>
